import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notificationApi } from "store/notification/api/notificationapi";
import type { AppDispatch, RootState } from "store/store";
import { assetApi } from "store/twin/api/assetapi";
import { emissionApi } from "store/twin/api/emissionapi";
import { passportApi } from "store/twin/api/passportapi";
import { txPassportOutputApi } from "store/twin/api/txpassporteoutputapi";
import { twinApi } from "store/twin/api/twinapi";
import { vaultApi } from "store/vault/api/vaultapi";
import { authApi } from "../api/authapi";
import type { Preference, UserState } from "../types/types";
import { configServiceApi } from "store/configService/api/configserviceapi";

const initialState: UserState = {
  token: "",
  userid: "",
  userbaddress: "",
  org: undefined,
  roles: [],
  userpreferences: [],
  actionPrivs: [],
};

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();

export const logOutThunk = createAppAsyncThunk<void, void>("auth/logout", async (_, { dispatch }) => {
  dispatch(authApi.util.resetApiState());
  dispatch(vaultApi.util.resetApiState());
  dispatch(notificationApi.util.resetApiState());
  dispatch(twinApi.util.resetApiState());
  dispatch(passportApi.util.resetApiState());
  dispatch(emissionApi.util.resetApiState());
  dispatch(txPassportOutputApi.util.resetApiState());
  dispatch(assetApi.util.resetApiState());
  dispatch(configServiceApi.util.resetApiState())
  localStorage.removeItem("jwt");
  localStorage.removeItem("user");
  return;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<UserState>) => {
      state.token = action.payload.token;
      state.userid = action.payload.userid;
      state.userbaddress = action.payload.userbaddress;
      state.org = action.payload.org;
      state.roles = action.payload.roles;
      state.userpreferences = action.payload.userpreferences;
      state.actionPrivs = action.payload.actionPrivs;
    },
    setPreferences: (state, action: PayloadAction<{ userpreferences: Preference[] }>) => {
      state.userpreferences = action.payload.userpreferences;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logOutThunk.fulfilled, () => {
      return initialState;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setAuth, setPreferences } = authSlice.actions;

export default authSlice.reducer;
