import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/slice/authSlice";
import ApiMiddleware401 from "./middleware/401APImiddleware";
import twinReducer from "./twin/slice/twinSlice";

import { setupListeners } from "@reduxjs/toolkit/query";
import { asApi } from "./aggregation/api/aggregationServiceApi";
import { authApi } from "./auth/api/authapi";
import { configServiceApi } from "./configService/api/configserviceapi";
import { docModuleApi } from "./documentModule/api/docModuleapi";
import { notificationApi } from "./notification/api/notificationapi";
import { pinataApi } from "./pinata/api/pinataapi";
import { assetApi } from "./twin/api/assetapi";
import { emissionApi } from "./twin/api/emissionapi";
import { passportApi } from "./twin/api/passportapi";
import { txPassportOutputApi } from "./twin/api/txpassporteoutputapi";
import { twinApi } from "./twin/api/twinapi";
import { vaultApi } from "./vault/api/vaultapi";

const store = configureStore({
  reducer: {
    auth: authReducer,
    twin: twinReducer,
    [authApi.reducerPath]: authApi.reducer,
    [vaultApi.reducerPath]: vaultApi.reducer,
    [twinApi.reducerPath]: twinApi.reducer,
    [docModuleApi.reducerPath]: docModuleApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [asApi.reducerPath]: asApi.reducer,
    [passportApi.reducerPath]: passportApi.reducer,
    [emissionApi.reducerPath]: emissionApi.reducer,
    [txPassportOutputApi.reducerPath]: txPassportOutputApi.reducer,
    [assetApi.reducerPath]: assetApi.reducer,
    [configServiceApi.reducerPath]: configServiceApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // TODO: need to check for better way to do this
      serializableCheck: false,
      // {
      //   ignoredActions: ["vaultApi/executeQuery/fulfilled"],
      //   // ignoredPaths: [`vaultApi.queries.fetchDocument("5e3eb318-1e59-4ff6-903e-befc91bbfdf2").data.blob`],
      //   ignoredPaths: ["vaultApi.queries.*.data.blob"],
      // },
    }).concat(
      authApi.middleware,
      twinApi.middleware,
      docModuleApi.middleware,
      vaultApi.middleware,
      configServiceApi.middleware,
      pinataApi.middleware,
      notificationApi.middleware,
      asApi.middleware,
      passportApi.middleware,
      emissionApi.middleware,
      txPassportOutputApi.middleware,
      assetApi.middleware,
      ApiMiddleware401
    ),
});

setupListeners(store.dispatch);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
