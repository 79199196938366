import { AddressZero } from "@ethersproject/constants";
import { message } from "antd";
import jwtDecode from "jwt-decode";
import { useGetUserQuery } from "store/auth/api/userapi";
import { logOutThunk, setAuth } from "store/auth/slice/authSlice";
import { JWTUser, PrivilegeResponse, UserResponse } from "store/auth/types/types";
import { useAppDispatch, useAppSelector } from "./hooks";

export const useAuth = () => {
  const authData = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { data: userData } = useGetUserQuery(authData.userid, { skip: !authData.userid });
  if (authData.token !== "") return { isLoggedIn: true, expired: false };
  const jwtFromStorage = localStorage.getItem("jwt");
  if (jwtFromStorage) {
    const decoded: JWTUser = jwtDecode(jwtFromStorage);

    const expired = decoded.exp < Date.now() / 1000;
    if (expired) {
      message.error({
        content: "Session timed out. Please log in to continue.",
        key: "updatable",
        duration: 3,
      });
      dispatch(logOutThunk() as any);
      return { isLoggedIn: false, expired: true };
    } else {
      const user: UserResponse = userData ?? JSON.parse(localStorage.getItem("user") ?? "");
      const authObj = {
        token: jwtFromStorage,
        userid: decoded.userId,
        userbaddress: decoded.baddress === "" ? AddressZero : decoded.baddress.toLowerCase(),
        org: user?.org,
        roles: user?.roles ?? [],
        userpreferences: user?.userpreferences ?? [],
        actionPrivs: new Array<string>(),
      };
      const actionPrivs = new Array<PrivilegeResponse>();
      authObj.roles.forEach((role) => {
        actionPrivs.push(...(role.privs.filter((priv) => priv.type === "Action")?.flat() ?? []));
      });
      authObj.actionPrivs = actionPrivs.map(({ name }) => name);

      dispatch(setAuth(authObj));
      return { isLoggedIn: true, expired: false };
    }
  }
  return { isLoggedIn: false, expired: true };
};
