import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { type ProductResponse } from "store/configService/types/types";
import {
  Document,
  GraphDataResponse,
  TransactionResponse,
  TwinAdvanceFiltersConfig,
  TwinExtendedRespose,
  TwinsVolume,
  type DocumentResponse,
  type Twin,
  type TwinMetaData,
  type TwinMetaDataResponse,
  type TwinResponse,
} from "store/twin/types/types";
import type { Pagination } from "store/types/types";

export const twinApi = createApi({
  reducerPath: "twinApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/ps/",
  }),
  tagTypes: [
    "Twin",
    "TwinDocuments",
    "Assignee",
    "Transaction",
    "Identifier",
    "EventIdentifier",
    "AttributeFormulas",
    "Attributes",
    "TwinTransactions",
    "ComposedEvents",
    "Event",
    "Facility",
    "Certificate",
    "EventFilters",
    "GraphConfigs",
    "ListConfig",
    "DocHeart",
    "ProductTransaction",
    "CertificationScope",
    "Pinata",
  ],
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (build) => ({
    // getTwins: build.query<
    //   Pagination<TwinExtendedRespose> & { page?: number },
    //   QueryParameters<TwinResponse> & {
    //     isRequested?: boolean;
    //     filter?: string;
    //     page?: number;
    //     ownership?: string;
    //     userid?: string;
    //     userbaddress?: string;
    //   }
    // >({
    //   query: (params) => {
    //     return {
    //       url: "twins",
    //       params,
    //     };
    //   },
    //   providesTags: (result) =>
    //     result
    //       ? [...result.rows.map(({ ID }) => ({ type: "Twin" as const, id: ID })), { type: "Twin", id: "LIST" }]
    //       : [{ type: "Twin", id: "LIST" }],
    // }),
    getFilteredTwins: build.query<
      Pagination<TwinExtendedRespose> & { page?: number },
      Partial<Pagination> & {
        filter?: string;
        page?: number;
        order?: string;
        searchFilter?: string;
        facilityId?: number;
        saleFilter?: string;
        co2Filter?: string;
      }
    >({
      query: (params) => {
        return {
          url: "twins/filter",
          method: "GET",
          params: { ...params, filter: params.filter ?? undefined },
        };
      },
      providesTags: (result) =>
        result
          ? [...result.rows.map(({ ID }) => ({ type: "Twin" as const, id: ID })), { type: "Twin", id: "LIST" }]
          : [{ type: "Twin", id: "LIST" }],
    }),
    // getTwinsInIds: build.query<TwinExtendedRespose[], number[]>({
    //   query: (body) => {
    //     return {
    //       url: "twins/ids",
    //       method: "POST",
    //       body,
    //     };
    //   },
    //   providesTags: (_result, _error, args) => [...args.map((arg) => ({ type: "Twin" as const, id: arg }))],
    // }),
    getTwin: build.query<TwinExtendedRespose & { commodityData?: ProductResponse }, number>({
      query: (id) => `twins/${id}`,
      // queryFn: async (id, _queryApi, _extraOptions, fetchWithBQ) => {
      //   const { error, data } = await fetchWithBQ(`twins/${id}`);
      //   if (error) return { error };
      //   const { data: commodityData } = await fetchWithBQ(`references/products/${(data as TwinExtendedRespose).productType}`);

      //   return {
      //     data: { ...(data as TwinExtendedRespose), commodityData } as TwinExtendedRespose & {
      //       commodityData?: ProductResponse;
      //     },
      //   };
      // },
      providesTags: (_result, _error, id) => [
        { type: "Twin", id },
        { type: "TwinTransactions", id },
      ],
    }),
    addTwin: build.mutation<TwinResponse, Twin>({
      query(body) {
        return {
          url: "twins",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Twin", id: "LIST" }],
    }),
    updateTwin: build.mutation<TwinResponse, Partial<Twin> & { ID: number }>({
      query(body) {
        return {
          url: "twins",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (_result, _error, { ID }) => [
        { type: "Twin", id: ID },
        { type: "TwinTransactions", id: ID },
      ],
    }),
    // deleteTwin: build.mutation<void, number>({
    //   query(id) {
    //     return {
    //       url: `twins/${id}`,
    //       method: "DELETE",
    //     };
    //   },
    //   invalidatesTags: (_result, _error, id) => [{ type: "Twin", id: id }],
    // }),
    getAdvanceTwinFiltes: build.query<TwinAdvanceFiltersConfig[], void>({
      query: () => {
        return {
          url: "advancefilters",
        };
      },
      providesTags: ["Twin"],
    }),
    addTwinMetaData: build.mutation<TwinMetaDataResponse, { metadata: TwinMetaData[] }>({
      query(body) {
        return {
          url: `twins/metadata`,
          method: "POST",
          body,
        };
      },
    }),
    getTwinMetaData: build.query<TwinMetaDataResponse[], string>({
      query: (hash) => `twins/metadata/${hash}`,
      providesTags: ["Twin"],
    }),
    // getDocuments: build.query<DocumentResponse[], string>({
    //   query: (hash) => `twins/document/${hash}`,
    //   providesTags: ["Twin"],
    // }),
    // getPossibleFacilities: build.query<FacilityResponse[], void>({
    //   query: () => `twins/facilities`,
    //   providesTags: ["Twin"],
    // }),
    // getFacilityCommodities: build.query<string[], number>({
    //   query: (facilityId) => `/twins/commodities/${facilityId}`,
    //   providesTags: ["Twin"],
    // }),
    // getOwnedTwinsCommodities: build.query<string[], void>({
    //   query: () => `/twins/owned/commodities`,
    //   providesTags: ["Twin"],
    // }),

    getTwinTransactions: build.query<TransactionResponse[], number>({
      query: (twinId) => `/twins/${twinId}/transactions`,
      providesTags: ["TwinTransactions"],
    }),
    // getTwinParents: build.query<TwinResponse[], number>({
    //   query: (twinId) => `/twins/${twinId}/parents`,
    //   providesTags: ["Twin"],
    // }),
    linkTwinTransactions: build.mutation<TransactionResponse, { twinId: number; transactionId: number }>({
      query(input) {
        return {
          url: `/twins/${input.twinId}/transaction/${input.transactionId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["TwinTransactions"],
    }),

    linkChildrenToTwin: build.mutation<void, { twinId: number; childTwinId: number }>({
      query(input) {
        return {
          url: `/twins/${input.twinId}/children/${input.childTwinId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Twin"],
    }),
    getTwinsVolume: build.query<TwinsVolume[], number>({
      query: (facilityId) => `/twins/volume/${facilityId}`,
      providesTags: ["Twin"],
    }),

    // Twin Documents
    getTwinDocuments: build.query<DocumentResponse[], number>({
      query: (twinId) => `/twins/${twinId}/documents`,
      providesTags: ["TwinDocuments"],
    }),
    AddTwinDocuments: build.mutation<void, { twinId: number; documents: Document[] }>({
      query: (input) => {
        return {
          url: `/twins/${input.twinId}/documents`,
          method: "POST",
          body: { documents: input.documents },
        };
      },
      invalidatesTags: ["TwinDocuments"],
    }),
    // RemoveTwinDocuments: build.mutation<void, { twinId: number; documents: Document[] }>({
    //   query: (input) => {
    //     return {
    //       url: `/twins/${input.twinId}/documents`,
    //       method: "POST",
    //       body: { documents: input.documents },
    //     };
    //   },
    //   invalidatesTags: ["TwinDocuments"],
    // }),

    // Twin Graph Data
    getTwinGraphData: build.query<GraphDataResponse[], { facilityId: number; identifierNames: string[] }>({
      query: (body) => {
        return {
          url: `/twins/graphdata/${body.facilityId}`,
          method: "POST",
          body,
        };
      },
      providesTags: ["Twin"],
    }),
  }),
});

export const {
  // useGetDocumentsQuery,
  useGetTwinQuery,
  useLazyGetTwinQuery,
  // useGetTwinsQuery,
  useGetTwinMetaDataQuery,
  // useGetPossibleFacilitiesQuery,
  // useGetFacilityCommoditiesQuery,
  // useGetOwnedTwinsCommoditiesQuery,
  useAddTwinMutation,
  useUpdateTwinMutation,
  // useDeleteTwinMutation,
  useAddTwinMetaDataMutation,
  // useGetTwinsInIdsQuery,
  useLinkChildrenToTwinMutation,
  useGetFilteredTwinsQuery,
  useLazyGetFilteredTwinsQuery,
  useGetAdvanceTwinFiltesQuery,
  useGetTwinsVolumeQuery,
  useLazyGetTwinsVolumeQuery,

  // Twin Transactions
  useGetTwinTransactionsQuery,
  useLazyGetTwinTransactionsQuery,
  // useGetTwinParentsQuery,
  // useLazyGetTwinParentsQuery,
  useLinkTwinTransactionsMutation,

  //Twin Documents
  useGetTwinDocumentsQuery,
  useLazyGetTwinDocumentsQuery,
  useAddTwinDocumentsMutation,
  // useRemoveTwinDocumentsMutation,

  // Twin Graph Data
  useGetTwinGraphDataQuery,
} = twinApi;
