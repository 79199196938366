import { lazy } from "react";
import type { RouteProps } from "react-router-dom";

const Profile = lazy(() => import("features/Profile").then((module) => ({ default: module.Profile })));
const Preferences = lazy(() => import("features/Preferences").then((module) => ({ default: module.Preferences })));
const Organization = lazy(() => import("features/Administration").then((module) => ({ default: module.Organization })));
const Priv = lazy(() => import("features/Administration").then((module) => ({ default: module.Priv })));
const Role = lazy(() => import("features/Administration").then((module) => ({ default: module.Role })));
const User = lazy(() => import("features/Administration").then((module) => ({ default: module.User })));
const Facility = lazy(() => import("features/Administration").then((module) => ({ default: module.Facility })));
const FacilityCreate = lazy(() =>
  import("features/Administration").then((module) => ({ default: module.FacilityCreate }))
);
const Certificate = lazy(() => import("features/Administration").then((module) => ({ default: module.Certificates })));

const CertificationScope = lazy(() =>
  import("features/Administration").then((module) => ({ default: module.CertificationScope }))
);
const Product = lazy(() => import("features/Configuration").then((module) => ({ default: module.Product })));
const DocTypes = lazy(() => import("features/Configuration").then((module) => ({ default: module.DocTypes })));

const Units = lazy(() => import("features/Configuration").then((module) => ({ default: module.Units })));
const Partners = lazy(() => import("features/Configuration").then((module) => ({ default: module.Partners })));
const Identifier = lazy(() => import("features/Configuration").then((module) => ({ default: module.Identifier })));
const Attribute = lazy(() => import("features/Configuration").then((module) => ({ default: module.Attribute })));
const DocumentRules = lazy(() =>
  import("features/Configuration").then((module) => ({ default: module.DocumentRules }))
);

const TwinList = lazy(() => import("features/twinList").then((module) => ({ default: module.TwinList })));
const Twin = lazy(() => import("features/twinPage").then((module) => ({ default: module.Twin })));
const TwinTransaction = lazy(() =>
  import("features/twinTransaction").then((module) => ({ default: module.TwinTransaction }))
);
const ComposedEventPage = lazy(() =>
  import("features/composedEvents").then((module) => ({ default: module.ComposedEventPage }))
);
const ComposedEventsOverview = lazy(() =>
  import("features/Configuration").then((module) => ({ default: module.ComposedEvents }))
);
const VaultDocument = lazy(() => import("features/Documents").then((module) => ({ default: module.VaultDocument })));
const Report = lazy(() => import("features/Report/index").then((module) => ({ default: module.Report })));
const Dashboard = lazy(() => import("features/Dashboard").then((module) => ({ default: module.Dashboard })));

const ProductRelease = lazy(() =>
  import("features/ProductRelease").then((module) => ({ default: module.ProductRelease }))
);

const DocumentModule = lazy(() =>
  import("features/DocumentModule").then((module) => ({ default: module.DocumentModule }))
);

export const protectedRoutes: RouteProps[] = [
  {
    path: "/twins",
    render: () => <TwinList />,
    exact: false,
  },
  {
    path: "/twin/:twinId",
    render: () => <Twin />,
    exact: true,
  },
  {
    path: "/twin/:twinId/transaction/:txId",
    render: () => <TwinTransaction />,
    exact: true,
  },
  {
    path: "/user",
    render: () => <User />,
    exact: true,
  },
  {
    path: "/role",
    render: () => <Role />,
    exact: true,
  },
  {
    path: "/priv",
    render: () => <Priv />,
    exact: true,
  },
  {
    path: "/org",
    render: () => <Organization />,
    exact: true,
  },
  {
    path: "/profile",
    render: () => <Profile />,
    exact: true,
  },
  {
    path: "/pref",
    render: () => <Preferences />,
    exact: true,
  },
  {
    path: "/unit",
    render: () => <Units />,
    exact: true,
  },
  {
    path: "/partners",
    render: () => <Partners />,
    exact: true,
  },
  {
    path: "/product",
    render: () => <Product />,
    exact: true,
  },
  {
    path: "/product-release",
    render: () => <ProductRelease />,
    exact: true,
  },
  {
    path: "/doctypes",
    render: () => <DocTypes />,
    exact: true,
  },
  {
    path: "/document",
    render: () => <VaultDocument />,
    exact: true,
  },
  {
    path: "/report",
    render: () => <Report />,
    exact: true,
  },
  {
    path: "/identifier",
    render: () => <Identifier />,
    exact: true,
  },
  {
    path: "/attribute",
    render: () => <Attribute />,
    exact: true,
  },
  {
    path: "/composedevents/update/:id",
    render: () => <ComposedEventPage />,
    exact: false,
  },
  {
    path: "/composedevents/create/",
    render: () => <ComposedEventPage />,
    exact: true,
  },
  {
    path: "/composedevents",
    render: () => <ComposedEventsOverview />,
    exact: true,
  },
  {
    path: "/facilities",
    render: () => <Facility />,
    exact: true,
  },
  {
    path: "/facilities/create/:id?",
    render: () => <FacilityCreate />,
    exact: true,
  },
  {
    path: "/certificates",
    render: () => <Certificate />,
    exact: true,
  },
  {
    path: "/certificationscopes",
    render: () => <CertificationScope />,
    exact: true,
  },
  {
    path: "/dashboard",
    render: () => <Dashboard />,
    exact: true,
  },
  {
    path: "/documentmodule",
    render: () => <DocumentModule />,
    exact: true,
  },
  {
    path: "/documentrules",
    render: () => <DocumentRules />,
    exact: true,
  },
];
