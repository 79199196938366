import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const configServiceApi = createApi({
  reducerPath: "configApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/config/",
  }),
  tagTypes: [
    "Attributes",
    "Certificate",
    "CertificationScope",
    "ComposedEvents",
    "EventFilters",
    "EventIdentifier",
    "AttributeFormulas",
    "Identifier",
    "ListConfig",
    "Product",
    "Unit",
    "Facility",
    "GraphConfigs",
    "EventEmissionConfig",
    "EventPassportConfig",
  ],
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (build) => ({}),
});
