import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TxPassportOutput, TxPassportOutputResponse } from "../types/types";

export const txPassportOutputApi = createApi({
  reducerPath: "txPassportOutputApi",
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: "/ps/txpassport",
  }),
  tagTypes: ["TxPassportOutput"],
  endpoints: (build) => ({
    getTxPassportByTxID: build.query<TxPassportOutputResponse[], number>({
      query: (txId) => `/transaction/${txId}`,
      providesTags: ["TxPassportOutput"],
    }),
    addTxPassport: build.mutation<TxPassportOutputResponse, TxPassportOutput>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["TxPassportOutput"],
    }),
  }),
});

export const { useGetTxPassportByTxIDQuery, useAddTxPassportMutation } = txPassportOutputApi;
