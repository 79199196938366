import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Emission, EmissionResponse } from "../types/types";

export const emissionApi = createApi({
  reducerPath: "emissionApi",
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: "/ps/emission",
  }),
  tagTypes: ["emission", "emissionConfig"],
  endpoints: (build) => ({
    // getEmissionByID: build.query<EmissionResponse, number>({
    //   query: (emissionId) => `/${emissionId}`,
    //   providesTags: ["emission"],
    // }),
    // getEmissionByPassportID: build.query<EmissionResponse, number>({
    //   query: (passportId) => `/passport/${passportId}`,
    //   providesTags: ["emission"],
    // }),
    getEmissionByTwinID: build.query<EmissionResponse, number>({
      query: (twinId) => `/twin/${twinId}`,
      providesTags: ["emission"],
    }),
    getEmissionsByTransactionID: build.query<EmissionResponse[], number>({
      query: (transactionId) => `/transaction/${transactionId}`,
      providesTags: ["emission"],
    }),
    // getEmissions: build.query<Pagination<EmissionResponse>, QueryParameters<Emission>>({
    //   query: (params) => ({
    //     url: "/",
    //     method: "GET",
    //     params,
    //   }),
    //   providesTags: ["emission"],
    // }),
    addEmission: build.mutation<EmissionResponse, Emission>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["emission"],
    }),
    updateEmission: build.mutation<void, Emission & { ID: number }>({
      query: (body) => ({
        url: "/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["emission"],
    }),
    // deleteEmission: build.mutation<void, number>({
    //   query: (emissionId) => ({
    //     url: `/${emissionId}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["emission"],
    // }),
    // deleteEmissionByPassportID: build.mutation<void, number>({
    //   query: (passportId) => ({
    //     url: `/passport/${passportId}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["emission"],
    // }),
  }),
});

export const {
  // useGetEmissionByIDQuery,
  // useGetEmissionByPassportIDQuery,
  useGetEmissionByTwinIDQuery,
  useLazyGetEmissionByTwinIDQuery,
  useGetEmissionsByTransactionIDQuery,
  // useGetEmissionsQuery,
  useAddEmissionMutation,
  useUpdateEmissionMutation,
  // useDeleteEmissionMutation,
  // useDeleteEmissionByPassportIDMutation,
} = emissionApi;
